export const SupportBold = ({ color, size }: { color?: string; size?: number }) => {
  return (
    <svg
      width={size || "32"}
      height={size || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 16.0977C30 23.8296 23.732 30.0977 16 30.0977C8.26801 30.0977 2 23.8296 2 16.0977C2 8.36567 8.26801 2.09766 16 2.09766C23.732 2.09766 30 8.36567 30 16.0977Z"
        stroke={color || "#2E6276"}
        strokeWidth="4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9682 23.089L14.9708 22.181C11.4285 18.9689 9.08984 16.8504 9.08984 14.2504C9.08984 12.1318 10.7544 10.4673 12.8729 10.4673C14.0697 10.4673 15.2184 11.0244 15.9682 11.9049C16.7179 11.0244 17.8666 10.4673 19.0634 10.4673C21.1819 10.4673 22.8465 12.1318 22.8465 14.2504C22.8465 16.8504 20.5078 18.9689 16.9655 22.1879L15.9682 23.089Z"
        fill={color || "#2E6276"}
      />
    </svg>
  );
};
