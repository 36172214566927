export const defaultKeywords = [
  "Career",
  "Job Training",
  "Resources",
  "Tuition Assistance",
  "Career Pathways",
  "Training Explorer",
  "New Jersey Training Explorer",
  "Career Navigator",
  "My Career NJ",
  "jobs",
  "careers",
  "nj jobs",
  "nj careers",
  "Career Resources NJ",
  "Job Training NJ",
  "New Jersey Career Pathways",
  "NJ Training Explorer",
  "Career Navigator NJ",
  "Tuition Assistance NJ",
  "Apprenticeship Programs NJ",
  "New Jersey Department of Labor",
  "NJ Job Search Tools",
  "In-Demand Occupations NJ",
  "Professional Development NJ",
  "Career Support NJ",
  "NJ Career Guidance",
  "Job Search Help NJ",
  "Career Services NJ",
  "NJ Workforce Development",
  "Free Online Training NJ",
  "NJ Labor Market Information",
  "Community College Assistance NJ",
  "Training Provider Resources NJ",
  "Career resources Bergen County",
  "Job training Passaic County",
  "Workforce development Hudson County",
  "Employment opportunities Essex County",
  "Career Navigator Morris County",
  "Job assistance Middlesex County",
  "Training programs Somerset County",
  "Career services Mercer County",
  "Employment support Monmouth County",
  "Workforce development Union County",
  "Career resources Camden County",
  "Job training Burlington County",
  "Workforce development Atlantic County",
  "Employment opportunities Gloucester County",
  "Career Navigator Ocean County",
  "Employment services Newark NJ",
  "Job support Jersey City NJ",
  "Career training Trenton NJ",
  "Job assistance Atlantic City NJ",
  "Workforce programs Paterson NJ",
  "Job Placement NJ",
  "Career Counseling NJ",
  "Workforce Training NJ",
  "Employment Services NJ",
  "Skill Development NJ",
  "Job Search NJ",
  "Employment NJ",
  "Career Opportunities NJ",
  "Vocational Training NJ",
  "Job Fairs NJ",
  "Resume Building NJ",
  "Interview Preparation NJ",
  "Career Workshops NJ",
  "NJ Employment Resources",
  "Career Development NJ",
  "Online Courses NJ",
  "Continuing Education NJ",
  "Job Assistance NJ",
  "Career Advice NJ",
];
